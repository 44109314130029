/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"


export default class BbrSagProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Bygninger] Titel
   * @param {Object} options.bbrsearcher Datafordeler.bbrsearcher
   * @param {Object} options.bbrSearcher Datafordeler.bbrsearcher
   **/
  constructor(options = {}) {

    let defaultOptions = {
      buttonText: ""
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.typeId === "bygning" || result.typeId === "adresse" || result.typeId === "matrikelnumre")
    }

    this.fetcher = options.fetcher
    this.bbrSearcher = null
    if (options.bbrsearcher){
      this.bbrSearcher = options.bbrsearcher
    } else if (options.bbrSearcher){
      this.bbrSearcher = options.bbrSearcher
    }
    
  }
  getbuttonText(result) {
    if (this.buttonText !== '') {
      return this.buttonText
    } else {
      let tabtext = 'BBR-sager'
      if (result.typeId === "adresse" ||  result.typeId === "matrikelnumre")
        tabtext = 'BBR-sager for grunden'
      else if (result.typeId === "bygning")
        tabtext = 'BBR-sager for bygningen'     
      return tabtext
    }
  }
  
  async myHandler(result) {    
    let typeId = result.typeId
    let sager = []
    if (typeId === "bygning")
      sager = await this.bbrSearcher.getSagerForBygning(result)
    if (typeId === "adresse" || typeId === "matrikelnumre")
      sager = await this.bbrSearcher.getSagerForGrund(result)

    let detailItemsList
    if (sager.length > 0) {
      detailItemsList = new DetailItemsList({
        itemType: "result",
        name: "bbrsag_list",
        header: "BBR-sager"
      })
      for (let sag of sager) {
        detailItemsList.append({
          type: "result",
          result: sag
        })
      }
    } else {
      detailItemsList = new DetailItemsList({
        name: "bbrsag_list",
        header: "BBR-sager"
      })
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen sager fundet"
      })
    }
    return [detailItemsList.asItem()]
  }
  

}